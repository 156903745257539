import { Form } from "react-bootstrap";
import ColumnSelect from "./ColumnSelect";
import { useTranslation } from "react-i18next";

import { ALLERGENS, UNITS, NEW_INGREDIENT_CATEGORIES, MEAL_CATEGORIES, STORAGE_UNITS } from "common/constants";
import './index.scss';
import SelectAsyncPaginate from "components/selectAsyncPaginate";

const CustomSelect = ({ options, onChange, value, errorColumn, t }) => {
  return (
    <div className="custom-select">
      <select value={value ?? ""} onChange={onChange} style={{backgroundColor: "transparent", width: "70%", border: "none", outline: "none"}}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {t(option.label)}
          </option>
        ))}
      </select>
      {errorColumn && Object.keys(errorColumn)?.length ? <span style={{color: "red", fontSize: "17px", float: "right"}}>&#9888;</span>  : ""}
    </div>
  );
};

function Imported(props) {
  const { t  } = useTranslation();

  return (
    <div className="imported-table-container mt-4">
    <table className="table import-table">
      <tbody>
        {props.rows.map((row, index) => (
          <tr style={{backgroundColor: props.errors.find(e => e.index === index) ? "#ffcdd2" : ""}} key={index}>
            <td>
              <div className="d-flex">
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    checked={Boolean(props.selectedRows[index])}
                    onChange={(event) =>
                      props.handleSelectRow(event.target.checked, index)
                    }
                  />
                </Form.Group>
              </div>
            </td>
            {row.map((cell, i) =>
            {
             const errorColumn = props.errors.filter(i => i.index == index)?.find(e => e.colIndex == i)
             let isDropdown = false
             let isIngredientDropdown = false
             let options = []
             if(props.rows[0][i] === 'ingredient_unit' ||props.rows[0][i] === 'unit' ||props.rows[0][i] === 'recipe_unit' ||props.rows[0][i] === 'conditioning_unit'){
               isDropdown = true
               options = UNITS
             }else if (props.rows[0][i] === 'category') {
               isDropdown = true
               options = NEW_INGREDIENT_CATEGORIES
             }else if (props.rows[0][i] === 'type') {
              isDropdown = true
              options = MEAL_CATEGORIES
            }else if (props.rows[0][i] === 'storage_unit') {
              isDropdown = true
              options = STORAGE_UNITS
            }else if (props.rows[0][i] === 'ingredient'){
              isDropdown = true
              isIngredientDropdown = true
            }else if(props.rows[0][i] === 'allergens'){
              isDropdown = true
              options = ALLERGENS
            }
             return index === 0 ? (
                <th key={index + "-" + i}>
                  <ColumnSelect
                    title={cell}
                    onSelect={(value) => props.selectHeader(value, i)}
                    dropdownOptions={props.dropdownOptions}
                  />
                </th>
              ) : (
                isDropdown && isIngredientDropdown ? 
                (<td className="select" style={{width: "70%"}} title={errorColumn && Object.keys(errorColumn)?.length ? errorColumn.message : ""} key={index + "-" + i}>
                  <SelectAsyncPaginate
                    key="id"
                    query="ingredients"
                    dataField="ingredient_id"
                    placeholder={t("Select Products")}
                    value={{lable: cell, value: cell, name: cell}}
                    onChange={(type) => (v) => props.setData(props.rows.map((item, indx) => {
                      if(index === indx){
                        item[i] = v.name || undefined
                        props.setErrors(props.errors.filter(e => {
                          if(e.colIndex === i && e.index === index && e.property_type === "string" && item[i]?.length && isNaN(item[i])) {
                            return false
                          }
                          return true
                        }))
                      }
                      return item
                      }))}
                    mapper={(rows) =>
                      rows.map((row) => ({
                        ...row,
                        name: row.name + (row.brand ? ` (${row.brand})` : ""),
                      }))
                    }
                  />
                </td>) : isDropdown && !isIngredientDropdown ? 
                 (<td className="select" style={{width: "100%"}} title={errorColumn && Object.keys(errorColumn)?.length ? errorColumn.message : ""} key={index + "-" + i}>
                      <CustomSelect options={options} onChange={(e)=> props.setData(props.rows.map((item, indx) => {
                        if(index === indx){
                          item[i] = e.target.value || undefined
                          props.setErrors(props.errors.filter(e => {
                            if(e.colIndex === i && e.index === index && e.property_type === "string" && item[i]?.length && isNaN(item[i])) {
                              return false
                            }
                            return true
                          }))
                        }
                        return item
                        }))} value={cell === 'piece' ? 'item' : cell} errorColumn={errorColumn} t={t} 
                    /> 
                </td>) 
                :
                (<td className="input" key={index + "-" + i} title={errorColumn && Object.keys(errorColumn)?.length ? errorColumn.message : ""}>
                  <span>
                    <input style={{border: "none", background: "transparent", width: "90%"}} onChange={(e)=> props.setData(props.rows.map((item, indx) => {
                      if(index === indx){
                        item[i] = e.target.value || undefined
                        props.setErrors(props.errors.filter(e => {
                          if(e.colIndex === i && e.index === index && e.property_type === "number" && !isNaN(item[i])){
                            return false
                          }
                          if(e.colIndex === i && e.index === index && e.property_type === "string" && item[i]?.length && isNaN(item[i])) {
                            return false
                          }
                          return true
                        }))
                      }
                      return item
                    }))} value={cell ?? ""} />
                    {errorColumn && Object.keys(errorColumn)?.length ? <span style={{color: "red", fontSize: "17px", width: "10%"}}>&#9888;</span>  : ""}
                </span>
               </td>)
              )
            }
            )}
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
}

export default Imported;
